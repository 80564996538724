import {captureMessage} from '@sentry/gatsby';
import type {Session} from '@nib/types-session-api';
import axios, {AxiosRequestConfig} from 'axios';
import {SessionURLV1, InitialiseSessionURL, SessionURLV2} from '../../constants';
import {FundToId} from '@nib/phi-constants';
import {AxiosResponse, AxiosError} from '../types';
import {ValidJoinServicesRequest, createAxiosError} from '../utils';
import {UpdateSessionPayload, SessionQueryParams} from './sessionSlice';
import {stringify} from 'qs';
import {FUNNEL_NAME} from '../../services/utils';
import {SessionInitialValues} from './mappers/initialValues';
import {formatISO} from 'date-fns';
import {getMockCurrentDate} from '../../utils';

const addBrandDrivenParams = (params: SessionQueryParams) => {
  const funnelName = FUNNEL_NAME;
  const fundId = FundToId[funnelName];
  const fundIdVariation = fundId;

  if (!params.fundId) {
    params.fundId = fundId || fundIdVariation;
  }
  if (!params.joinSource) {
    params.joinSource = process.env.GATSBY_SOURCE_CHANNEL || 'NibWeb';
  }
};

/**
 * Initializes the session given an id
 * @param   {string} sessionId
 * @param   {string} scale
 * @param   {string} hospitalProductId
 * @param   {string} extrasProductId
 * @param   {string} frequency
 * @param   {string} state
 * @returns {Promise<AxiosResponse<Session | AxiosError>>}
 */
export const init = (body: SessionInitialValues, params: SessionQueryParams) => {
  delete params.directQuote;
  addBrandDrivenParams(params);
  const mockedCurrentDate = getMockCurrentDate();
  const options: AxiosRequestConfig = {
    validateStatus: ValidJoinServicesRequest,
    headers: mockedCurrentDate ? {'x-mock-current-date': mockedCurrentDate} : undefined
  };
  const queryParams = stringify(params, {skipNulls: true});
  body = {
    ...body,
    clientTimeStamp: formatISO(new Date())
  };
  return axios
    .post<Session>(`${InitialiseSessionURL}?${queryParams}`, body, options)
    .then((res) => {
      const response: AxiosResponse<Session> = {
        data: res.data,
        type: 'Session'
      };
      return response;
    })
    .catch((error) => {
      console.error('Session init error -- ', error);
      captureMessage(`Session init error -- ${error}`, 'error');
      const response: AxiosResponse<AxiosError> = {
        type: 'AxiosError',
        data: createAxiosError(error, 'GET')
      };
      return response;
    });
};

/**
 * Fetch the session given an id
 * @param   {string} sessionId
 * @returns {Promise<AxiosResponse<Session | AxiosError>>}
 */
export const fetch = (sessionId: string) => {
  const config = {
    validateStatus: ValidJoinServicesRequest,
    headers: {
      'Content-Type': 'application/json',
      Pragma: 'no-cache'
    }
  };
  const data = {id: sessionId};

  return axios
    .post<Session>(`${SessionURLV2}/fetch`, data, config)
    .then((res) => {
      const response: AxiosResponse<Session> = {
        data: res.data,
        type: 'Session'
      };
      return response;
    })
    .catch((error) => {
      console.error('Session fetch error -- ', error);
      captureMessage(`Session fetch error -- ${error}`, 'error');
      const response: AxiosResponse<AxiosError> = {
        type: 'AxiosError',
        data: createAxiosError(error, 'POST')
      };
      return response;
    });
};

/**
 * Updates the session through a put request, with the body containing the
 * session id and session data to update
 * @param   {object} sessionId: string, session: Session
 * @returns {Promise<AxiosResponse<Session | AxiosError>>}
 */
export const put = (putData: UpdateSessionPayload) => {
  const mockedCurrentDate = getMockCurrentDate();
  const options: AxiosRequestConfig = {
    validateStatus: ValidJoinServicesRequest,
    headers: mockedCurrentDate ? {'x-mock-current-date': mockedCurrentDate} : undefined
  };
  putData = {
    sessionId: putData.sessionId,
    session: {
      ...putData.session,
      clientTimeStamp: formatISO(new Date())
    }
  };
  return axios
    .put<UpdateSessionPayload, AxiosResponse<Session>>(`${SessionURLV1}/arhi/`, putData, options)
    .then((res) => {
      const response: AxiosResponse<Session> = {
        data: res.data,
        type: 'Session'
      };
      return response;
    })
    .catch((error) => {
      console.error('Session Put error -- ', error);
      captureMessage(`Session put error -- ${error}`, 'error');
      const response: AxiosResponse<AxiosError> = {
        type: 'AxiosError',
        data: createAxiosError(error, 'PUT')
      };
      return response;
    });
};
